export enum BiActionType {
  Discard_Changes = 'discard_changes',
  Visibility_Settings = 'visibility_settings_drawer',
  Edit_Url = 'edit_url',
  Blocked_Members_Drawer = 'blocked_members_drawer',
  Unblock_Selected_Members = 'unblock_selected_members',
  Unblock_Member_Confirm = 'unblock_confirm',
}

export const MY_ACCOUNT_REFERRAL = 'my_account';
